import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { SEORoutes, SAMPLE_RESUME_ROUTE, SAMPLE_RESUME_KEY_ROUTE, STATIC_BLOGS } from '.'
import { Home } from '../../containers'
import { PageNotFound } from '../../components'

export const SLASH_ROUTE = '/'
export const HOME_ROUTE = '/home'
export const PRODUCTS_ROUTE = '/products'
export const TERMS_ROUTE = '/terms-conditions'
export const PRIVACY_POLICY_ROUTE = '/privacy-policy'
export const TECH_STACK_ROUTE = '/technology-stack'
export const ABOUT = '/about'
export const CONTACT_US_ROUTE = '/contact'

export const MainRoutes = () => (
  <Switch>
    <Route exact path={SLASH_ROUTE} render={props => <Home {...props} />} />
    <Route exact path={HOME_ROUTE} render={props => <Home {...props} />} />
    <Route exact path={TERMS_ROUTE} render={props => <Home {...props} selectedKey={'terms-conditions'} />} />
    <Route exact path={PRIVACY_POLICY_ROUTE} render={props => <Home {...props} selectedKey={'privacy-policy'} />} />
    <Route exact path={ABOUT} render={props => <Home {...props} selectedKey={'about'} />} />
    <Route exact path={TECH_STACK_ROUTE} render={props => <Home {...props} selectedKey={'technology-stack'} />} />
    <Route exact path={PRODUCTS_ROUTE} render={props => <Home {...props} selectedKey={'products'} />} />
    <Route exact path={CONTACT_US_ROUTE} render={props => <Home {...props} selectedKey={'contact-us'} />} />
    <Route component={SEORoutes} exact path={[SAMPLE_RESUME_ROUTE, SAMPLE_RESUME_KEY_ROUTE, STATIC_BLOGS]} />
    <Route component={PageNotFound} path="*" />
  </Switch>
)
