/* eslint-disable max-len */
import React, { Component } from 'react'
import cs from 'classnames'
import { Container, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import st from './style.scss'

export default class MobileFriendly extends Component {
    static propTypes = {
      gotoURL: PropTypes.func.isRequired,
    }
    render() {
    //  const { gotoURL } = this.props
      return (
        <Container>
          <Row>
            <Col lg={12} md={12} xl={12}>
              <div className={cs(st.create_web_sec)}>
                <div className={cs(st.create_web_banner)}>
                  <img alt="chess_img" src="/assets/images/friendly_web.jpg" />
                </div>
                <h2>Create your identity</h2>
                <ul>
                  <li>By Admin</li>
                  <li>Jan 17, 2022</li>
                </ul>
                <p>You know it very well; your mobile number is identity and a source
                  mode to connect with you be it a business discussion or a family matter(s).
                  Our Name, Business Name and address are some example of identifying ourselves
                  from the others. So, do you have such identify which take you to global scale?
                  Yes, it&lsquo;s a global identity. One universal name for anyone in the world or
                  your neighbourhood can reach your business with one unique name followed by world wide web, shortly www.</p>
                <div className={cs(st.create_content)}>
                  <h5>Why are we talking about web address now?</h5>
                  <p>Just 3 years ago on 5 September 2016, the India&lsquo;s largest mobile networks
                    started offering 4G services per month cheaper than a water bottle price.
                    That’s the revolutions in Indian common men get access to the world class
                    web-based services and boosted hundreds of large business be available on web
                    in hands of common men’s smart phone screen. Indian mobile history is referred
                    as before and after JIO, so in last 3 years more than 45
                    crore mobiles are sold which means so nearly half of the population access world wide web every day.</p>
                  <p>There are more than 5.1 domains registered in India as of year-end 2017. And the growth is at
                    14.5% which is far higher than global growth. The clear indication is India business are
                    going online at a lighting speed, so secure your domain name and connect us one of our sales
                    team for best web site hosting services in India. We provide you complete suite and hassle
                    free solution that is starting form the concept to hosting of a professionally designed website
                    by our expert team in AARWII TechSolutions Pvt Ltd, we are the recent comer to this arena offering
                    cutting edge solutions to small to medium businesses. Our team is accessible to your just in a matter
                    of phone call or simply WhatsApp us. We provide most suitable web solution, website design and development along with hosting.
                    Our packages are customized to suitable for local business starting as low as price less than a plain dosa per day.</p>
                </div>
                <div className={cs(st.create_content)}>
                  <h5>What you get?</h5>
                  <p>Most important element in web presence is the beautiful design creates
                    an impact full influence your customer and business users. We design elegant
                    professional WebSite in a state that you can plan to lunch in days to week time.
                    Every design comes from AARWII is well tested and optimized for Web and mobile view, we design themes
                    best suitable for almost in any screen starting the compact smart phone to large monitors used by professionals.</p>
                  <p>And another most impact factor is the speed of WebSite, yes
                    in today’s world nobody waits many seconds to load the websites so
                    better than find an alternative website which can offer similar solutions
                    or services as your business so it can easily switch a potential custom from
                    payer to not even a visitor, so we assure you’re the speed. Our performance
                    management team runs variety of tests to ensure the website is loaded in all the cases in rapid speed.
                    Yes, we follow customization throughout the design to make pages as much as light weight as possible.</p>
                  <p>For best WebSite design, hosting and maintenance requirement just call our team,
                    try asking your tricky questions before you commit buying service from us. We assure you, service quality is our priority.
                    Every website we design will be monitored by our intelligent inhouse
                    built robot which can alert us for immediate corrective action.</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      )
    }
}
