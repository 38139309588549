/* eslint-disable max-len */
import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Container, Row, Col, Carousel } from 'react-bootstrap'
import cs from 'classnames'
import Card from 'react-bootstrap/Card'
import st from './style.scss'
import { Icon, IconType } from '../../common'

class Home extends Component {
static propTypes = {
  gotoURL: PropTypes.func.isRequired,
  loginWithRedirect: PropTypes.func.isRequired,
}
constructor(props) {
  super(props)
  this.state = {
    showFullTestmonial: false,
  }
}
showFullTestmonial = () => {
  const { showFullTestmonial } = this.state
  this.setState({
    showFullTestmonial: !showFullTestmonial,
  })
}
render() {
  // const { gotoURL } = this.props
  const { Globe_1, SETTING, Graph, Cloud, Bank, BarChart } = IconType.AARWII
  //  const { showFullTestmonial } = this.state
  return (
    <Fragment>
      <Container className={cs(st.container)} fluid>
        <Carousel className={cs(st.carousel_item)}>
          <Carousel.Item>
            <img
              alt="First slide"
              className="d-block w-100"
              src={'/assets/images/slide-1.jpg'}
               />
            <Carousel.Caption className={cs(st.carousel_captions)}>
              <div className={cs(st.caption)}>
                <h2>World Class Solutions <br />From the Architects</h2>
                <p>Our chief Architect has delivered solutions for Fortune 500 companies <br />Now, offers solutions for Small, Medium Businesses.</p>
                <div className={cs(st.buttons)}>
                  <div className={cs(st.readmore)} onClick={() => this.props.gotoURL('/services')}>Read More</div>
                  <div className={cs(st.contactus)} onClick={() => this.props.gotoURL('/contact')}>Contact Us</div>
                </div>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              alt="First slide"
              className="d-block w-100"
              src={'/assets/images/slide-2.jpg'}
               />
            <Carousel.Caption className={cs(st.carousel_captions)}>
              <div className={cs(st.caption)}>
                <h2> From concept to <br />Creation.</h2>
                <p>Bring technology to your hands, leverage experts knowledge <br /> {'We Delivery IT'}</p>
                <div className={cs(st.buttons)}>
                  <div className={cs(st.readmore)} onClick={() => this.props.gotoURL('/services')}>Read More</div>
                  <div className={cs(st.contactus)} onClick={() => this.props.gotoURL('/contact')}>Contact Us</div>
                </div>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              alt="First slide"
              className="d-block w-100"
              src={'/assets/images/slide-3.jpg'}
               />
            <Carousel.Caption className={cs(st.carousel_captions)}>
              <div className={cs(st.caption)}>
                <h2>Your business Web presence crafted.</h2>
                <p>We carefully choose templates, fonts and colour theme <br /> For vibrant presentation.</p>
                <div className={cs(st.buttons)}>
                  <div className={cs(st.readmore)} onClick={() => this.props.gotoURL('/services')}>Read More</div>
                  <div className={cs(st.contactus)} onClick={() => this.props.gotoURL('/contact')}>Contact Us</div>
                </div>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              alt="First slide"
              className="d-block w-100"
              src={'/assets/images/slide-4.jpg'}
               />
            <Carousel.Caption className={cs(st.carousel_captions)}>
              <div className={cs(st.caption)}>
                <h2>Secure, Cloud Solutions <br />World Class AWS platform.</h2>
                <p>All website hosting by us uses strong <br />security and deployed into Amazon Cloud.</p>
                <div className={cs(st.buttons)}>
                  <div className={cs(st.readmore)} onClick={() => this.props.gotoURL('/services')}>Read More</div>
                  <div className={cs(st.contactus)} onClick={() => this.props.gotoURL('/contact')}>Contact Us</div>
                </div>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </Container>
      <div className={cs(st.section_grey, st.section)}>
        <Container>
          <div className={cs(st.content_section)}>
            <h3>Right Technology Partner is key for <span>SUCCESS</span>
              <span>We deliver IT</span>, We have done it for Fortune companies</h3>
            <p>Our team is just a call away, highly skilled team manages the services, a robot constantly monitors all websites</p>
          </div>
        </Container>
      </div>
      <div className={cs(st.section_white, st.section)}>
        <Container>
          <Row>
            <Col className={cs(st.col_margin)} lg={4} md={4} sm={12} xl={4} xs={12}>
              <div className={cs(st.offer_box)}>
                <img
                  alt="service"
                  src="/assets/images/service-1.jpg" />
                <div className={cs(st.offer, st.details)}>
                  <div className={cs(st.offer_title)}>
                    Don&lsquo;t know IT ?
                  </div>
                  <p>Findng right IT partner matters, every solution we create is reviewed and approved by our chief Architect who designed large projects for multi-national companies. So, your solutions quality and standards are assured. </p>
                  <div className={cs(st.offer_link)}>{'CONTINUE RED >'} </div>
                </div>
              </div>
            </Col>
            <Col className={cs(st.col_margin)} lg={4} md={4} sm={12} xl={4} xs={12}>
              <div className={cs(st.offer_box)}>
                <img
                  alt="service"
                  src="/assets/images/service-2.jpg" />
                <div className={cs(st.offer, st.details)}>
                  <div className={cs(st.offer_title)}>
                    Web Presense
                  </div>
                  <p>Create a beautiful impression to your customers.In today&lsquo;s competitive world, one must always be in the first row of the race. IT boots such opportunities for Small Medium Business and the Cloud Solutions are the way for easily adopting to technology . </p>
                  <div className={cs(st.offer_link)}>{'CONTINUE RED >'} </div>
                </div>
              </div>
            </Col>
            <Col className={cs(st.col_margin)} lg={4} md={4} sm={12} xl={4} xs={12}>
              <div className={cs(st.offer_box)}>
                <img
                  alt="service"
                  src="/assets/images/service-3.jpg" />
                <div className={cs(st.offer, st.details)}>
                  <div className={cs(st.offer_title)}>
                    e-Commerce
                  </div>
                  <p> Get your shopping cart ready, have direct leads hit your store, get ready to beat the competition.</p>
                  <div className={cs(st.offer_link)}> {'CONTINUE RED >'} </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={cs(st.section_grey, st.section)}>
        <div className={cs(st.title_pattern)}>
          <h4>WHAT WE DO</h4>
          <div className={cs(st.under_line)}>
            <div className={cs(st.under_line_left)} />
            <div className={cs(st.under_line_right)} />
          </div>
        </div>
        <Container>
          <Row>
            <Col lg={4} md={4} xl={4}>
              <div className={cs(st.we_do, st.details)}>
                <div className={cs(st.we_do_icon)}>
                  <Icon
                    color={'#fff'}
                    height={30}
                    names={[Globe_1]}
                    viewHeight={'256'}
                    viewWidth={'256'}
                    width={30}
                  />
                </div>
                <h4>Web Design</h4>
                <p>Picture perfect designs and high-end graphic modules bring your business an
                  elegant look. A huge collection of models are ready for your selection.
                </p>
              </div>
            </Col>
            <Col lg={4} md={4} xl={4}>
              <div className={cs(st.we_do, st.details)}>
                <div className={cs(st.we_do_icon)}>
                  <Icon
                    color={'#fff'}
                    height={30}
                    names={[SETTING]}
                    viewHeight={'1920'}
                    viewWidth={'1792'}
                    width={30}
                  />
                </div>
                <h4>Technology Provider</h4>
                <p>For today&lsquo;s agile demands, adoption of right technology is the driver for success.
                  We provide you technology to ensure your services are in the front line of the race.
                </p>
              </div>
            </Col>
            <Col lg={4} md={4} xl={4}>
              <div className={cs(st.we_do, st.details)}>
                <div className={cs(st.we_do_icon)}>
                  <Icon
                    color={'#fff'}
                    height={30}
                    names={[BarChart]}
                    viewHeight={'512'}
                    viewWidth={'512'}
                    width={30}
                  />
                </div>
                <h4>Complete Solution</h4>
                <p>We understand the business demands at it&lsquo;s core competences, so we provide you a
                  transparent IT model assuring your time spent on IT is very minimal.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={4} md={4} xl={4}>
              <div className={cs(st.we_do, st.details)}>
                <div className={cs(st.we_do_icon)}>
                  <Icon
                    color={'#fff'}
                    height={30}
                    names={[Cloud]}
                    viewHeight={'1024'}
                    viewWidth={'1024'}
                    width={30}
                  />
                </div>
                <h4>Cloud Platform</h4>
                <p>All your website &amp; IT services are hosted on world class Cloud platforms, we will
                  assist your services to be on most advanced technology stream.
                </p>
              </div>
            </Col>
            <Col lg={4} md={4} xl={4}>
              <div className={cs(st.we_do, st.details)}>
                <div className={cs(st.we_do_icon)}>
                  <Icon
                    color={'#fff'}
                    height={30}
                    names={[Graph]}
                    viewHeight={'16'}
                    viewWidth={'16'}
                    width={30}
                  />
                </div>
                <h4>We deliver IT</h4>
                <p>We know, in the business SPEED is the most important factor in this competitive
                  world, we work on well defined KPI&lsquo;s so we make sure your service is delivered on time.
                </p>
              </div>
            </Col>
            <Col lg={4} md={4} xl={4}>
              <div className={cs(st.we_do, st.details)}>
                <div className={cs(st.we_do_icon)}>
                  <Icon
                    color={'#fff'}
                    height={30}
                    names={[Bank]}
                    viewHeight={'1920'}
                    viewWidth={'1792'}
                    width={30}
                  />
                </div>
                <h4>Our Competency Scale</h4>
                <p>Our first priority is quality, the makers of aarwii are pioneered in six-sigma
                  process and delivered a wide variety of solutions to the top companies in the world.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={cs(st.section_black, st.section)}>
        <Container>
          <Row>
            <Col lg={7} md={7} xl={7} xs={12}>
              <h4>What should I know for web enablement?</h4>
              <p>A common question pops in the mind when you want to bring IT to the business. What you need is, just
                express your views on how your business web presence should look like.. That&lsquo;s it!. The rest we assure you, it
                will be delivered.
              </p>
            </Col>
            <Col lg={5} md={5} xl={5} xs={12}>
              <div className={cs(st.buttons)}>
                <div className={cs(st.readmore)} onClick={() => this.props.gotoURL('/services')}>Read More</div>
                <div className={cs(st.contactus)} onClick={() => this.props.gotoURL('/contact')}>Contact Us</div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={cs(st.section_grey, st.section)}>
        <div className={cs(st.title_pattern)}>
          <h4>FROM THE BLOG</h4>
          <div className={cs(st.under_line)}>
            <div className={cs(st.under_line_left)} />
            <div className={cs(st.under_line_right)} />
          </div>
        </div>
        <Container>
          <Row>
            <Col className={cs(st.col_margin)} lg={4} md={4} xl={4} xs={12}>
              <Card className={cs(st.blog)}>
                <Card.Img src="/assets/images/blog-tn-1.jpg" variant="top" />
                <Card.Body className={cs(st.blog_content, st.details)}>
                  <Card.Title className={cs(st.blog_content_title)} onClick={() => this.props.gotoURL('/blogs/create-web-identity')}>Create your Identity, beat the competition</Card.Title>
                  <Card.Text className={cs(st.blog_content_admin)}>
                    <p><span>By Admin</span> October 17,2021</p>
                  </Card.Text>
                  <p>The landing page, when a user try to access your website they will see landing page – it should be like
                    what you see in first 3-4 seconds.
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col className={cs(st.col_margin)} lg={4} md={4} xl={4} xs={12}>
              <Card className={cs(st.blog)}>
                <Card.Img src="/assets/images/blog-tn-2.jpg" variant="top" />
                <Card.Body className={cs(st.blog_content, st.details)}>
                  <Card.Title className={cs(st.blog_content_title)} onClick={() => this.props.gotoURL('/blogs/how-to-select-web-design')}>How do you select a best website design for your business ?</Card.Title>
                  <Card.Text className={cs(st.blog_content_admin)}>
                    <p><span>By Admin</span> Febraury 19,2022</p>
                  </Card.Text>
                  <p>The landing page, when a user try to access your website they will see landing page – it should be like
                    what you see in first 3-4 seconds.
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col className={cs(st.col_margin)} lg={4} md={4} xl={4} xs={12}>
              <Card className={cs(st.blog)}>
                <Card.Img src="/assets/images/blog-tn-3.jpg" variant="top" />
                <Card.Body className={cs(st.blog_content, st.details)}>
                  <Card.Title className={cs(st.blog_content_title)} onClick={() => this.props.gotoURL('/blogs/mobile-friendly-website')}>What is mobile friendly website ?</Card.Title>
                  <Card.Text className={cs(st.blog_content_admin)}>
                    <p><span>By Admin</span> January 17,2022</p>
                  </Card.Text>
                  <p>The landing page, when a user try to access your website they will see landing page – it should be like
                    what you see in first 3-4 seconds.
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}
}
export default withRouter(Home)
