import { stores } from '../../store'

const hideToast = () => {
  stores.toastModel.hideToast()
}

const removeToast = () => {
  stores.toastModel.removeToast()
}

const DEFAULT_SHOW_TIME = 5000

export default class ToastService {
  showMessage({ message, severity }) {
    stores.toastModel.showToast(message, hideToast, severity, DEFAULT_SHOW_TIME)
  }

  clearAllMessages() {
    removeToast()
  }
}
