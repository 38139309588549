/* eslint-disable max-len */
import React, { Component, Fragment } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import cs from 'classnames'
import PropTypes from 'prop-types'
import RightPanel from '../../common/RightPanel'
import st from './style.scss'

export default class Products extends Component {
static propTypes = {
  gotoURL: PropTypes.func.isRequired,
}
render() {
  const { gotoURL } = this.props
  return (
    <Fragment>
      <section>
        <div className={cs(st.about_banner)}>
          <img alt="about_banner" src={'/assets/images/about_banner.jpg'} />
        </div>
      </section>
      <section className={cs(st.main_content)}>
        <Container>
          <Row>
            <Col className={cs(st.left_Content)} lg={8} md={6} xl={8}>
              <div className={cs(st.about_content_text)}>
                <h4 className={cs(st.about_content_title)}>RESUMAKER</h4>
                <p><strong>The trending online resume builder</strong></p>
                <p>A Resume builder creates your graphically rich resume almost instantly, select your preferences,
                  key-in your data and use unlimited customization options to curve your resume.
                </p>
                <p>AI Power content generation for the objective and summary statements.
                </p>
              </div>
              <div className={cs(st.temlates_header)}>
                <p><strong>Templates to chose from</strong></p>
              </div>
              <Row>
                <Col className={cs(st.temlates)} md={6}>
                  <Card style={{ width: '18rem' }}>
                    <Card.Img src="/assets/images/orion.jpg" variant="top" />
                    <Card.Body>
                      <Card.Title><a href="https://www.resumaker.co.in/resume-templates">Orion</a></Card.Title>
                      <Card.Text>
                        Best option in town is me, a popular choice of many users.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col className={cs(st.temlates)} md={6}>
                  <Card style={{ width: '18rem' }}>
                    <Card.Img src="/assets/images/iris.jpg" variant="top" />
                    <Card.Body>
                      <Card.Title><a href="https://www.resumaker.co.in/resume-templates">Iris</a></Card.Title>
                      <Card.Text>
                        Trendy with a brilliant presentation that everyone is impressed.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col className={cs(st.temlates)} md={6}>
                  <Card style={{ width: '18rem' }}>
                    <Card.Img src="/assets/images/cetus.jpg" variant="top" />
                    <Card.Body>
                      <Card.Title><a href="https://www.resumaker.co.in/resume-templates">Cetus</a></Card.Title>
                      <Card.Text>
                        Simplicity meets elegance. Exclusively for you.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col className={cs(st.temlates)} md={6}>
                  <Card style={{ width: '18rem' }}>
                    <Card.Img src="/assets/images/mars.jpg" variant="top" />
                    <Card.Body>
                      <Card.Title><a href="https://www.resumaker.co.in/resume-templates">Mars</a></Card.Title>
                      <Card.Text>
                        Create a blasting presence of yourself this time
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col lg={4} md={6} xl={4}>
              <RightPanel active={'products'} gotoURL={gotoURL} />
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}
}
