// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".create_web_sec___2zTU2{padding:50px 0}.create_web_banner___orsfJ img{width:100%;height:auto}.create_web_sec___2zTU2 h2{font-size:20px;font-weight:600;margin-top:30px;margin-bottom:10px;color:#333}.create_web_sec___2zTU2 p{margin-top:10px}.create_web_sec___2zTU2 ul li{display:inline-block;margin-right:10px;font-size:12px}.create_web_sec___2zTU2 p{font-size:13px;line-height:25px;font-weight:normal}.create_content___1QSV8{margin-top:20px}.create_content___1QSV8 h5{font-weight:700}.create_content___1QSV8 p{margin-top:20px}\n", "",{"version":3,"sources":["C:/AARWII/aarwii/Git/aarwii-web-app/src/components/InformativeContent/Blogs/style.scss"],"names":[],"mappings":"AAAA,wBACE,cAAc,CACf,+BAEC,UAAU,CACV,WAAW,CACZ,2BAEC,cAAe,CACb,eAAgB,CAChB,eAAgB,CAChB,kBAAmB,CACnB,UAAW,CACd,0BAEC,eAAe,CAChB,8BAEC,oBAAqB,CACrB,iBAAiB,CACjB,cAAe,CAChB,0BAEC,cAAe,CACf,gBAAiB,CACjB,kBAAmB,CACpB,wBAEC,eAAe,CAChB,2BAEC,eAAgB,CACjB,0BAEC,eAAe","file":"style.scss","sourcesContent":[".create_web_sec {\r\n  padding:50px 0; \r\n}\r\n.create_web_banner img {\r\n  width:100%;\r\n  height:auto;\r\n}\r\n.create_web_sec h2 {\r\n  font-size: 20px;\r\n    font-weight: 600;\r\n    margin-top: 30px;\r\n    margin-bottom: 10px;\r\n    color: #333;\r\n}\r\n.create_web_sec p {\r\n  margin-top:10px;\r\n}\r\n.create_web_sec ul li {\r\n  display: inline-block;\r\n  margin-right:10px;\r\n  font-size: 12px;\r\n}\r\n.create_web_sec p {\r\n  font-size: 13px;\r\n  line-height: 25px;\r\n  font-weight: normal;\r\n}\r\n.create_content {\r\n  margin-top:20px;\r\n}\r\n.create_content h5 {\r\n  font-weight: 700;\r\n}\r\n.create_content p {\r\n  margin-top:20px;\r\n}"]}]);
// Exports
exports.locals = {
	"create_web_sec": "create_web_sec___2zTU2",
	"createWebSec": "create_web_sec___2zTU2",
	"create_web_banner": "create_web_banner___orsfJ",
	"createWebBanner": "create_web_banner___orsfJ",
	"create_content": "create_content___1QSV8",
	"createContent": "create_content___1QSV8"
};
module.exports = exports;
