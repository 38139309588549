const dataUrl = process.env.DATA_URL
const dashboardHost = process.env.DASHBOARD_HOST
const frontEndUrl = 'https://www.resumaker.co.in'
const resourcesUrl = 'https://d2vtatw9bzj7wp.cloudfront.net'
const oAuthHostUrl = 'https://dashboard.resumaker.co.in'
export const AppConstants = {
  dataUrl,
  resourcesUrl,
  dashboardHost,
  frontEndUrl,
  oAuthHostUrl,
}
