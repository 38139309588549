/* eslint-disable max-len */
import React, { Component } from 'react'
import cs from 'classnames'
import { Container, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import RightPanel from '../../common/RightPanel'
import st from './style.scss'

export default class CloudHosting extends Component {
    static propTypes = {
      gotoURL: PropTypes.func.isRequired,
    }
    render() {
      const { gotoURL } = this.props
      return (
        <div>
          <div className={cs(st.create_web_banner)}>
            <img alt="about_banner" src={'/assets/images/about_banner.jpg'} />
          </div>
          <Container>
            <Row className={cs(st.create_web_sec)}>
              <Col lg={8} md={8} xl={8}>
                <div className={cs(st.create_web_banner)}>
                  <img alt="chess_img" src="/assets/images/cloud_hosting.jpg" />
                </div>
                <h2>Cloud Hosting</h2>
                <p>There are major cloud players in global market which drives the enterprises demand and supply the computing power. But the implementation is at the enterprise how they ahieve the business demand. Our team offer extensive knowledge and deployment strategy.
                </p>
                <p> Efficiently managed cloud hosting continues to be the key preference for most companies. Companies need powerful managed cloud hosting solutions to handle any sort of data. To tackle this challenge, organizations need a way to manage their cloud infrastructure. Some organizations can handle the cloud infrastructure by themselves, but some need managed cloud service providers in India to alleviate their cloud infrastructure. But honestly, managing your cloud infrastructure yourself calls for highly skilled manpower, exceptional expertise, and dedicated resources.</p>
                <p> Managed Cloud hosting is one of the top IT cloud services that is completely managed by the MSP (Managed Service Provider). All the maintenance requirements are handled by the Cyfuture Cloud experts (certified MSP). Our experts have an appropriate cloud approach for solving problems. We handle common as well as critical issues such as - security, computing, storage, and network operations. We proactively solve every issue efficiently.</p>
                <div className={cs(st.create_content)}>
                  <h5>Cost benifits</h5>
                  <p>Cut your cloud bill by half and pay only for the cloud resources you utilize. We have the best CPU performance per dollar when equated to AWS, Google, and Azure. You can choose from the various managed cloud hosting plans. We are here to help. Our experts ensure that your cloud runs smoothly. You can easily connect with our support experts freely around the clock. You can also get premier support if you need any help.</p>
                </div>
                <div className={cs(st.create_content)}>
                  <h5>implement Managed services</h5>
                  <p>You can deploy applications on the cloud instead of utilizing a single server. Solutions can be automated & controlled by using web portals, APIs, and applications. Consumers can scale up and down cloud storage, RAM, and other components. It supports different databases such as - SQL, MySQL, and more.</p>
                </div>
              </Col>
              <Col lg={4} md={6} xl={4}>
                <RightPanel active={'services-cloud-hosting'} gotoURL={gotoURL} />
              </Col>
            </Row>
          </Container>
        </div>
      )
    }
}
