import { api } from './api-helper'

import { getMappedUrl, GET_BLOG_LIST, GET_BLOG_DATA, GET_RELATED_BLOGS, GET_INTERVIEW_QS_CATEGORIES,
  GET_INTERVIEW_QS_LIST, GET_INTERVIEW_QS_DATA, GET_RELATED_INTERVIEW_QS,
  GET_CAREER_GUIDE_LIST, GET_CAREER_GUIDE_SECTION, GET_TEMPLATES, SUBSCRIBE_NEWSLETTER, GENERATE_CAPTCHA,
  CONTACT_US, GET_RECENT_BLOGS } from '../constants'

export async function getBlogList({ pageNo, tag, category, filter }) {
  let response
  try {
    const searchObject = { page: pageNo, tag, category, filter }
    const url = getMappedUrl(GET_BLOG_LIST, searchObject)
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    console.error('Error occurred')
    // ErrorHandlingService.displayMessageArray({ message: get(err, 'body.messages'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function getRecentBlogs() {
  let response
  try {
    const url = getMappedUrl(GET_RECENT_BLOGS)
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    console.error('Error occurred')
    // ErrorHandlingService.displayMessageArray({ message: get(err, 'body.messages'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function getRelatedBlogs(count = 3) {
  let response
  try {
    const url = getMappedUrl(GET_RELATED_BLOGS, { count })
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    console.error('Error occurred')
    // ErrorHandlingService.displayMessageArray({ message: get(err, 'body.messages'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function subscribeNewSLetter(email) {
  let response
  try {
    const searchObject = { emailId: email }
    const url = getMappedUrl(SUBSCRIBE_NEWSLETTER, searchObject)
    response = await api(url, { method: 'POST', body: {} })
  }
  catch (err) {
    console.error('Error occurred')
    // ErrorHandlingService.displayMessageArray({ message: get(err, 'body.messages'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function getBlogData(blogId = '') {
  let response
  try {
    const searchObject = { blogId }
    const url = getMappedUrl(GET_BLOG_DATA, searchObject)
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    console.error('Error occurred')
    // ErrorHandlingService.displayMessageArray({ message: get(err, 'body.messages'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function getInterviewQuestionsCategories(pageNo = 1) {
  let response
  try {
    const searchObject = { page: pageNo }
    const url = getMappedUrl(GET_INTERVIEW_QS_CATEGORIES, searchObject)
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    console.error('Error occurred')
    // ErrorHandlingService.displayMessageArray({ message: get(err, 'body.messages'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function getInterviewQuestionsList(searchObject = {}) {
  let response
  try {
    const url = getMappedUrl(GET_INTERVIEW_QS_LIST, searchObject)
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    console.error('Error occurred')
    // ErrorHandlingService.displayMessageArray({ message: get(err, 'body.messages'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function getInterviewQuestionsDetails(id) {
  let response
  try {
    const searchObject = { id }
    const url = getMappedUrl(GET_INTERVIEW_QS_DATA, searchObject)
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    console.error('Error occurred')
    // ErrorHandlingService.displayMessageArray({ message: get(err, 'body.messages'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function getRelatedInterviewQuestions(category, count) {
  let response
  try {
    const searchObject = { category, count }
    const url = getMappedUrl(GET_RELATED_INTERVIEW_QS, searchObject)
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    console.error('Error occurred')
    // ErrorHandlingService.displayMessageArray({ message: get(err, 'body.messages'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function getCareerGuideList(pageNo = 1) {
  let response
  try {
    const searchObject = { page: pageNo }
    const url = getMappedUrl(GET_CAREER_GUIDE_LIST, searchObject)
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    console.error('Error occurred')
    // ErrorHandlingService.displayMessageArray({ message: get(err, 'body.messages'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function getCareerGuideSectionData(careerId = '') {
  let response
  try {
    const searchObject = { id: careerId }
    const url = getMappedUrl(GET_CAREER_GUIDE_SECTION, searchObject)
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    console.error('Error occurred')
    // ErrorHandlingService.displayMessageArray({ message: get(err, 'body.messages'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function getTemplates() {
  let response
  try {
    const url = getMappedUrl(GET_TEMPLATES)
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    console.error('Error occurred')
    // ErrorHandlingService.displayMessageArray({ message: get(err, 'body.messages'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function generateCaptcha(requestId) {
  let response
  try {
    const url = getMappedUrl(GENERATE_CAPTCHA, { requestId })
    response = await api(url, { method: 'GET' })
  }
  catch (err) {
    console.error('Error occurred')
    // ErrorHandlingService.displayMessageArray({ message: get(err, 'body.messages'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function contactus(payload) {
  let response
  try {
    const url = getMappedUrl(CONTACT_US)
    response = await api(url, { method: 'POST', body: payload })
  }
  catch (err) {
    console.error('Error occurred')
    // ErrorHandlingService.displayMessageArray({ message: get(err, 'body.messages'), severity: SEVERITY.ERROR })
  }
  return response
}
