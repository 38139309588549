import { AppConstants } from '../application-constants'

export const GET_UNIVERSITIES_URI = 'api/guided/universities'
export const GET_CITIES_URI = 'api/guided/cities'
export const GET_STATES_URI = 'api/guided/states'
export const GET_COMPANIES_URI = 'api/guided/companies'
export const GET_EDUCATION_URI = 'api/guided/education'
export const GET_ROLES_URI = 'api/guided/roles'
export const GET_DOMAIN_URI = 'api/guided/domain'
export const GET_CERTIFICATIONS_URI = 'api/guided/certifications'
export const GET_TRAININGS_URI = 'api/guided/trainings'
export const GET_INSTITUTIONS_URI = 'api/guided/institutions'
export const GET_OBJECTIVES_URI = 'api/guided/objective'
export const GET_SUMMARIES_URI = 'api/guided/summary'
export const GET_ACHIEVEMENTS_URI = 'api/guided/achievements'
export const GET_SKILLS_URI = 'api/guided/skills'
export const GET_LANGUAGES_URI = 'api/guided/language'
export const GET_RESPONSIBILITIES_URI = 'api/guided/responsibility/search'
export const GET_EMPLOYMENT_RESPONSIBILITIES_URI = 'api/guided/employmentSummary'
export const GET_PROJECT_RESPONSIBILITIES_URI = 'api/guided/projectResponsibilities'
export const GET_HOBBIES_URI = 'api/guided/hobbies'
export const GET_TEMPLATES = 'api/templates/list'
export const GET_PROFILE_URI = 'api/user/info'
export const SAVE_PROFILE_URI = 'api/saveProfile'
export const CREATE_RESUME = 'api/user/resume'
export const UPDATE_RESUME = 'api/user/resume/$1'
export const FETCH_UPDATE_RESUME = 'api/user/resume/$1/json'
export const FETCH_RESUME_DETAILS = 'api/user/resume/$1/details'
export const ADD_TOKEN = 'api/user/urlToken/$1'

export const FETCH_SUBSCRIBERS = 'api/admin/report/sub/$1/$2'
export const FETCH_ORDERS_REPORT = 'api/admin/report/order/$1/$2'
export const FETCH_RESUMES_REPORT = 'api/admin/report/resume/$1/$2'
export const FETCH_SUBSCRIPTION_REPORT = 'api/admin/report/subscription/$1/$2'
export const FETCH_USERS = 'api/admin/report/user/$1/$2'
export const FETCH_VISITORS = 'api/admin/report/visitor/$1/$2'
export const FETCH_DETAILS = 'api/admin/report/details/$1/$2'

export const FETCH_SAVE_PDF = 'api/user/resume/$1/pdf/create'
export const FETCH_SAVE_IMAGE = 'api/user/resume/$1/image'
export const FETCH_RESUME_IMAGES = 'api/user/resume/images'
export const FETCH_RESUMES = 'api/user/resume/list'
export const LOGIN_USER = 'api/user/login'
export const LOGOUT_USER = 'api/user/logout'
export const FETCH_TOKEN = 'oauth/token'
export const REGISTER_USER = 'api/login/user'
export const VALIDATE_OTP = 'api/login/otp'
export const PERSONAL_VIEW = 'api/user/info'
export const PERSONAL_SAVE = 'api/user/info'
export const RESET_PASSWORD = 'api/login/pass'
export const CHANGE_PASSWORD = 'api/user/pass'
export const RESUME_DOWNLOAD = 'api/resume/download'
export const FETCH_DASHBOARD = 'api/user'
export const FETCH_BILLING_DETAIL = 'api/user/sub/$1'
export const FETCH_BILLING_LIST = 'api/user/sub'
export const DOWNLOAD_RESUME = 'api/user/resume/$1/pdf'
export const CREATE_SUB_ORDER = 'api/user/payment/order'
export const UPDATE_SUB_ORDER = 'api/user/payment/order/$1'
export const INITIATE_TRXN = 'api/user/payment/initiate'
export const ADMIN_GERENAL_REPORT = 'api/admin/report/graph/$1/$2/$3' // 10-05-2019/10-10-2021/hourly'

// Payment GateWay URL
export const INITIATE_PAYMENT_GATEWAY_CALL = AppConstants.paymentUrl
// export const INITIATE_PAYMENT_GATEWAY_CALL = 'https://secure.payu.in/_payment'
