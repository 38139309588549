/* eslint-disable max-len */
import React, { Component, Fragment } from 'react'
import { Container, Row, Col, Accordion, Card, Tabs, Tab } from 'react-bootstrap'
import cs from 'classnames'
import PropTypes from 'prop-types'
import st from './style.scss'
import { Icon, IconType } from '../../common'
import RightPanel from '../../common/RightPanel'

export default class About extends Component {
static propTypes = {
  gotoURL: PropTypes.func.isRequired,
}
render() {
  const { Graph, BarChart, BullHorn } = IconType.AARWII
  const { gotoURL } = this.props
  return (
    <Fragment>
      <section>
        <div className={cs(st.about_banner)}>
          <img alt="about_banner" src={'/assets/images/about_banner.jpg'} />
        </div>
      </section>
      <section className={cs(st.main_content)}>
        <Container>
          <Row>
            <Col className={cs(st.left_Content)} lg={8} md={6} xl={8}>
              <div className={cs(st.about_content_text)}>
                <h4 className={cs(st.about_content_title)}>WELCOME TO AARWII</h4>
                <p><strong>We are start-up. Our Management team is pioneer in IT since 2004</strong></p>
                <p>AARWII TechSolutions Pvt Ltd is a technology enabler for Small to Medium business with offerings of a wide
                  range of solutions. The organization is incorporated early 2018 and being operated from Amaravati, Andhra
                  Pradesh. The team behind AARWII are passionate technologists with a track record of working with Fortune 500
                  companies in the world. We have handled Information Technology solution design, development and maintenance of
                  medium to large programs. Our vision is inspired to bring IT close to Small Medium Enterprises for their
                  solutions and services, and our motivation is extend SME leverage and experience innovations &amp; technology
                  advancement which was limited to big and major players. We provide end to end solutions for web enablement of
                  SME, we connect with people but not only limited to the virtual world.
                </p>
                <p>Our team is closely accessible to our customer providing them the best solutions and services.
                </p>
                <p>Finally, in one line <b>We deliver IT </b>.</p>
                <div className={cs(st.about_content_text, st.item_column)}>
                  <div className={cs(st.item_two_column)}>
                    <h4 className={cs(st.about_content_title)}>WHY CHOISE AARWII ?</h4>
                    <p>There are many services providers in today&lsquo;s market, but we differ by</p>
                    <ul className={cs(st.flat_list, st.style1)}>
                      <li>WE ARE TECHNOLOGISTS</li>
                      <li>WE HAVE DESIGNED SOLUTIONS FOR MNC&lsquo;S</li>
                      <li>WE ARE FAST GROWING COMPANY</li>
                      <li>QUALITY IS OUR COMMITMENT</li>
                      <li>We handle a business IT end to end</li>
                      <li>We design solutions with niche technologies</li>
                      <li>We are a phone call away, our support team is instantly ready to assist you</li>
                    </ul>
                  </div>
                  <div className={cs(st.item_two_column)}>
                    <div className={cs(st.flat_tabs)}>
                      <Tabs defaultActiveKey="home">
                        <Tab eventKey="home" title="QUALITY">
                          <img alt="images" src="/assets/images/about_tab_1.jpg" />
                        </Tab>
                        <Tab eventKey="profile" title="SERVICE">
                          <img alt="images" src="/assets/images/about_tab_2.jpg" />
                        </Tab>
                        <Tab eventKey="contact" title="RESULT">
                          <img alt="images" src="/assets/images/about_tab_3.jpg" />
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} md={6} xl={4}>
              <RightPanel active={'about'} gotoURL={gotoURL} />
            </Col>
            <Col lg={8} md={8} sm={12} xl={8}>
              <Accordion defaultActiveKey="0">
                <Card className={cs(st.about_acc_Card)}>
                  <Accordion.Toggle as={Card.Header} className={cs(st.about_card_header)} eventKey="1">
                    <Icon
                      color={'#212121'}
                      height={25}
                      names={[Graph]}
                      viewHeight={'16'}
                      viewWidth={'16'}
                      width={25}
                        />
                    <h4 className={cs(st.h4_title)}>
                      We Are Passionate
                    </h4>
                  </Accordion.Toggle>
                  <div className={cs(st.h4_about_card_body)}>
                    <Accordion.Collapse eventKey="1">
                      <div className={cs(st.about_content_accord)}>
                        The chief Architect of AARWII TechSolutions was a Senior Architect for dozens of solutions delivered to <b>TOP
                          100 companies</b> in the world, the same passion became service today as AARWII.
                      </div>
                    </Accordion.Collapse>
                  </div>
                </Card>
                <Card className={cs(st.about_acc_Card)}>
                  <Accordion.Toggle as={Card.Header} className={cs(st.about_card_header)} eventKey="2">
                    <Icon
                      color={'#212121'}
                      height={25}
                      names={[BarChart]}
                      viewHeight={'512'}
                      viewWidth={'512'}
                      width={25}
                        />
                    <h4 className={cs(st.h4_title)}>
                      We Are Process Oriented
                    </h4>
                  </Accordion.Toggle>
                  <div className={cs(st.h4_about_card_body)}>
                    <Accordion.Collapse eventKey="2">
                      <div className={cs(st.about_content_accord)}>
                        Our vision is <b>Bring technology &amp; IT to Small Medium Enterprises</b>, Our motivation is provide platform
                        for SME to experience technology innovations and advancement.
                      </div>
                    </Accordion.Collapse>
                  </div>
                </Card>
                <Card className={cs(st.about_acc_Card)}>
                  <Accordion.Toggle as={Card.Header} className={cs(st.about_card_header)} eventKey="3">
                    <Icon
                      color={'#212121'}
                      height={25}
                      names={[BullHorn]}
                      viewHeight={'1200'}
                      viewWidth={'1200'}
                      width={25}
                        />
                    <h4 className={cs(st.h4_title)}>
                      We Are Honest &amp; Dependable
                    </h4>
                  </Accordion.Toggle>
                  <div className={cs(st.h4_about_card_body)}>
                    <Accordion.Collapse eventKey="3">
                      <div className={cs(st.about_content_accord)}>
                        Our core principle is quality and customer satisfaction, we strive and extend to achieve best
                        satisfaction levels. Every AARWII service we promise is transparent and we go extra mile in delivering such
                        commitment
                      </div>
                    </Accordion.Collapse>
                  </div>
                </Card>
              </Accordion>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}
}
