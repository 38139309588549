import React, { Component } from 'react'
import cs from 'classnames'
import { Container, Row, Col } from 'react-bootstrap'
import st from './style.scss'
import { Icon, IconType } from '../../common'

export default class ContactUs extends Component {
  render() {
    const { MAIL, PHONE, WHATSAPP } = IconType.COMMUNICATION

    return (
      <Container>
        <Row>
          <Col lg={12} md={12} xl={12}>
            <div className={cs(st.create_web_sec)}>
              <div className={cs(st.contact)}>
                <img alt="chess_img" src="/assets/images/contact.png" />
              </div>

              <Row>
                <Col lg={6} md={6} sm={12} xl={6}>
                  <div className={cs(st.contact)}>
                    <h4>Wtire to Us</h4>
                    <ul>
                      <li><div className={cs(st.contact_info)}>
                        <Icon
                          color={'#6c757dd1'}
                          height={'24'}
                          names={[MAIL]}
                          viewHeight={'24'}
                          viewWidth={'24'} width={'24'} />
                        <span>contact@aarwii.com</span></div></li>
                    </ul>
                  </div>
                </Col>
                <Col lg={6} md={6} sm={12} xl={6}>
                  <div className={cs(st.contact)}>
                    <h4>Call Us</h4>
                    <ul>
                      <li><div className={cs(st.contact_info)}>
                        <Icon
                          color={'#6c757dd1'}
                          height={'24'}
                          names={[PHONE]}
                          viewHeight={'24'}
                          viewWidth={'24'} width={'24'} />
                        <span>+91 9121 900 900</span></div></li>
                      <li><div className={cs(st.contact_info)}>
                        <Icon
                          color={'#6c757dd1'}
                          height={'24'}
                          names={[WHATSAPP]}
                          viewHeight={'24'}
                          viewWidth={'24'} width={'24'} />
                        <span>+91 9121 900 900</span></div></li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
}
