import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col, Card } from 'react-bootstrap'
import cs from 'classnames'
import st from './style.scss'

export default class Services extends Component {
static propTypes = {
  gotoURL: PropTypes.func.isRequired,
}

render() {
// const { gotoURL } = this.props
  return (
    <Fragment>
      <section>
        <div className={cs(st.service_banner)}>
          <img alt="about_banner" src={'/assets/images/about_banner.jpg'} />
        </div>
      </section>
      <section className={cs(st.card_sec)}>
        <Container>
          <Row>
            <Col lg={4} md={4} sm={6} xl={4}>
              <Card className={cs(st.card)}>
                <Card.Img src="/assets/images/web_design.jpg" variant="top" />
                <Card.Body>
                  <Card.Title className={cs(st.card_title)}>WEB DESIGN</Card.Title>
                  <p>
                    Our team has created hundreds of model themes for ready to pick,
                    these designs are loaded with rich color scheme and tested mobile compatibility
                  </p>
                  <div
                    className={cs(st.box_redmore)}
                    onClick={() => this.props.gotoURL('/services/web-design')}> {'CONTINUE >'}  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={4} md={4} sm={6} xl={4}>
              <Card className={cs(st.card)}>
                <Card.Img src="/assets/images/sm_business.jpg" variant="top" />
                <Card.Body className={cs(st.card_body)}>
                  <Card.Title className={cs(st.card_title)}>SMALL MEDIUM BUSINESS</Card.Title>
                  <p>A decado ago technology was only limited to large businesses or enterprises,
                    but the evolution created hundreds new era technologies and solutions.
                  </p>
                  <div
                    className={cs(st.box_redmore)}
                    onClick={() => this.props.gotoURL('/services/small-medium-business')}> {'CONTINUE >'} </div>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={4} md={4} sm={6} xl={4}>
              <Card className={cs(st.card)}>
                <Card.Img src="/assets/images/consultation.jpg" variant="top" />
                <Card.Body>
                  <Card.Title className={cs(st.card_title)}>CONSULTATION</Card.Title>
                  <p>
                    The management team has worked with fortune 500 companies enabling
                    multiple business variety of solutions, we have the knowledge..
                  </p>
                  <div
                    className={cs(st.box_redmore)}
                    onClick={() => this.props.gotoURL('/services/it-consulting')}> {'CONTINUE >'} </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className={cs(st.row_margin)}>
            <Col lg={4} md={4} sm={6} xl={4}>
              <Card className={cs(st.card)}>
                <Card.Img src="/assets/images/cloud_hosting.jpg" variant="top" />
                <Card.Body>
                  <Card.Title className={cs(st.card_title)}>CLOUD HOSTING</Card.Title>
                  <p>
                    Every website designed and hosted by us is moved to Amazon Cloud Server,
                    your web-site is assured high availability
                  </p>
                  <div
                    className={cs(st.box_redmore)}
                    onClick={() => this.props.gotoURL('/services/cloud-hosting')}> {'CONTINUE >'} </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4} md={4} sm={6} xl={4}>
              <Card className={cs(st.card)}>
                <Card.Img src="/assets/images/secure_solutions.jpg" variant="top" />
                <Card.Body>
                  <Card.Title className={cs(st.card_title)}>SECURE SOLUTIONS</Card.Title>
                  <p>
                    A web application security is key in gaining credibility, our business service offerings are
                    always accessible through a secure channel. We take care of security
                  </p>
                  <div
                    className={cs(st.box_redmore)}
                    onClick={() => this.props.gotoURL('/services/secure-solutions')}> {'CONTINUE >'} </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}
}
