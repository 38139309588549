import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { PagesContainer } from '../../containers'

export const SAMPLE_RESUME_ROUTE = '/services'
export const SAMPLE_RESUME_KEY_ROUTE = '/services/:key'
export const STATIC_BLOGS = '/blogs/:key'

export const SEORoutes = () => (
  <Switch>
    <Route
      exact path={[SAMPLE_RESUME_ROUTE, SAMPLE_RESUME_KEY_ROUTE]}
      render={props => <PagesContainer {...props} selectedKey={'sample'} />} />
    <Route
	exact path={[STATIC_BLOGS]}
	render={props => <PagesContainer {...props} selectedKey={'blogs'} />} />
  </Switch>
)
