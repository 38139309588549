/* eslint-disable max-len */
import React, { Component } from 'react'
import cs from 'classnames'
import { Container, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import RightPanel from '../../common/RightPanel'
import st from './style.scss'

export default class SecureSolutions extends Component {
    static propTypes = {
      gotoURL: PropTypes.func.isRequired,
    }
    render() {
      const { gotoURL } = this.props
      return (
        <div>
          <div className={cs(st.create_web_banner)}>
            <img alt="about_banner" src={'/assets/images/about_banner.jpg'} />
          </div>
          <Container>
            <Row className={cs(st.create_web_sec)}>
              <Col lg={8} md={8} xl={8}>
                <div className={cs(st.create_web_banner)}>
                  <img alt="chess_img" src="/assets/images/secure_solutions.jpg" />
                </div>
                <h2>Secure Solutions</h2>
                <p>A web journery is adventurous and enables the small or medium companies apprear and accessible at the global scale, but security risk limits and challenges to the peak.
                </p>
                <div className={cs(st.create_content)}>
                  <h5>Insecure APIs/interfaces</h5>
                  <p>Cloud service providers provide numerous interfaces and application programming interfaces to their organisations that are well-documented so that organisations can access and use them with ease.</p>
                  <p>However, suppose these interfaces aren’t properly secured—it creates potential risks and issues as cybercriminals can easily use the documentation made for the organisations to access and exploit sensitive data and information in the cloud environment.</p>
                </div>
                <div className={cs(st.create_content)}>
                  <h5>Unauthorised access</h5>
                  <p>MAny organisation’s cloud-based deployments and operations are present outside the network perimeter and can be easily accessed via the public internet.
                    While this facilitates accessibility for remote employees, it makes it effortless for attackers to leverage unauthorised access to the cloud resources. To ensure secured data access in the cloud, ensure proper security configurations and strong employee credentials.

                  </p>
                </div>
                <div className={cs(st.create_content)}>
                  <h5>Lack of visibility</h5>
                  <p> Traditional solutions like VPNs don’t meet the needs of modern enterprises and offer a lack of visibility to the organisations over the network, resource usage, and user activity.</p>
                  <p>This limits organisations’ ability to monitor their resources and protect them against online attacks.

                  </p>
                </div>
              </Col>
              <Col lg={4} md={6} xl={4}>
                <RightPanel active={'services-secure-solutions'} gotoURL={gotoURL} />
              </Col>
            </Row>
          </Container>
        </div>
      )
    }
}
