/* eslint-disable react/prop-types */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { inject } from 'mobx-react'
import classnames from 'classnames'
import { get } from 'lodash'

import MainHeader from '../../components/common/MainHeader'
import InformativeContent from '../../components/InformativeContent'
import MainFooter from '../../components/common/MainFooter'

import styles from './style.scss'

@inject('mainModel', 'applicationModel')
class PagesContainer extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    mainModel: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    selectedKey: PropTypes.string,
  }

  static defaultProps = {
    selectedKey: 'sample',
  }

  gotoURL = (url) => {
    const { history } = this.props
    history.push(url)
    window.scrollTo(0, 0)
  }

  getRenderComponent() {
    const { selectedKey, match } = this.props
    const selectedSubKey = get(match, 'params.key', '')
    switch (selectedKey) {
      case 'sample':
        return (
          <InformativeContent
            gotoURL={this.gotoURL}
            selectedSubKey={selectedSubKey} />
        )
      case 'blogs':
        return (
          <InformativeContent
            gotoURL={this.gotoURL}
            selectedSubKey={selectedSubKey} />
        )
      default:
        return <div>Default</div>
    }
  }

  render() {
    const { applicationModel } = this.props
    const isLoggedIn = applicationModel.getLogin()
    return (
      <div className={classnames(styles.main)}>
        <MainHeader
          gotoURL={this.gotoURL} isLoggedIn={isLoggedIn}
          loginWithRedirect={this.loginWithRedirect} logout={this.logout} />
        { this.getRenderComponent() }
        <MainFooter gotoURL={this.gotoURL} subscribe={this.subscribe} />
      </div>
    )
  }
}

export default withRouter(PagesContainer)
