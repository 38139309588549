import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Router } from 'react-router-dom'
import { createBrowserHistory as createHistory } from 'history'

import { PublicRouter } from '../../router'

import './appmain.scss'

const history = createHistory()

export default class AppContainer extends Component {
  constructor(props) {
    super(props)
    this.url = window.location.href
  }

  changeDocumentTitle() {
    document.title = 'Resumaker'
  }

  render() {
    return (
      <Router history={history}>
        <PublicRouter />
      </Router>
    )
  }
}

AppContainer.propTypes = {
  applicationModel: PropTypes.object.isRequired,
}
