import { observable, action, toJS } from 'mobx'
import { clone, isEmpty, omit, get, isFunction, has } from 'lodash'

import { registerUser, validateOTP, loginUser } from '../services'

export default class ApplicationModel {
  @observable basicUserInfo = {}
  @observable isLogin = false

  constructor() {
    this.initialize()
  }

  @action
  initialize() {
    const status = localStorage.getItem('isLogin')
    this.setLogin(status === 'true')
  }

  getLogin() {
    return this.isLogin
  }

  getBasicUserInfo() {
    if (isEmpty(this.basicUserInfo)) {
      this.basicUserInfo = JSON.parse(localStorage.getItem('userInfo'))
    }
    return toJS(this.basicUserInfo)
  }

  @action
  setBasicUserInfo(val) {
    this.basicUserInfo = val
  }

  @action
  setLogin(val) {
    this.isLogin = val
  }

  setRedirect(details) {
    this.redirectDetails = details
  }

  getRedirect() {
    return this.redirectDetails
  }

  removeRedirectFields(fields){
    if (this.redirectDetails && has(this.redirectDetails, fields)){
      const clonedRedirectDetails = clone(this.redirectDetails, true)
      this.redirectDetails = omit(this.redirectDetails, fields)
      return clonedRedirectDetails
    }
    return this.redirectDetails
  }

  @action
  async loginStatus(status) {
    this.setLogin(status)
    localStorage.setItem('isLogin', status)
  }

  initUser(userName, response) {
    const token = get(response, 'token')
    const profileId = get(response, 'profileId')
    if (!isEmpty(token)) {
      const basicInfo = { userName, token, profileId }
      localStorage.setItem('token', token)
      localStorage.setItem('userName', userName)
      localStorage.setItem('profileId', profileId)
      this.setBasicUserInfo(basicInfo)
    }
    return profileId
  }

  async login(payload, cb) {
    const response = await loginUser(payload)
    if (get(response, 'profileId')) {
      const profileId = get(response, 'profileId')
      this.setLogin(true)
      if (isFunction(cb)){
        cb(profileId)
      }
    }
  }

  async registerUser(payload, cb = () => {}) {
    const response = await registerUser(payload)
    if (!isEmpty(get(response, 'token'))) {
      if (isFunction(cb)){
        cb(response)
      }
    }
  }

  async validateOTP(payload, cb = () => {}) {
    const response = await validateOTP(payload)
    if (response && isFunction(cb)){
      cb(response)
    }
  }
}
