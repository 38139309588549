import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'mobx-react'
import * as mobX from 'mobx'
import { toast } from 'react-toastify'

import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/styles/style.scss'
import { AppContainer } from './containers'
import { stores } from './store'

mobX.configure({ enforceActions: true })
toast.configure()

render(
  <Provider {...stores}>
    <div>
      <AppContainer {...stores} />
    </div>
  </Provider>,
  document.getElementById('root'),
)

window.store = stores
