/* eslint-disable no-return-await */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
import 'whatwg-fetch' // Required for sending cookies.
import { observable } from 'mobx'
import { set, endsWith } from 'lodash'
import { getMappedUrl, LOGOUT_USER, FETCH_TOKEN } from '../constants'

export const apiResponse = observable({ response: {} })
export const apiResponseCode = observable({ code: undefined })

const modifyParams = params => ({
  ...params,
  // mode: 'no-cors',
  // credentials: 'include', // Required for sending cookies
})

const authHeader = headers => {
  if (localStorage.getItem('isLogin')) {
    const token = localStorage.getItem('authToken')
    set(headers, 'authorization', token)
  }
}

const storeAuthorization = headers => {
  const authToken = headers.get('Authorization')
  if (authToken){
    const refreshToken = headers.get('Refresh-Token')
    localStorage.setItem('authToken', authToken)
    localStorage.setItem('isLogin', true)
    if (refreshToken){
      localStorage.setItem('refreshToken', refreshToken)
    }
  }
}

const removeAuthorization = uri => {
  if (endsWith(uri, LOGOUT_USER)){
    localStorage.removeItem('authToken')
    localStorage.setItem('isLogin', false)
    localStorage.removeItem('refreshToken')
  }
}

const refreshToken = async (statusCode, callback = _ => _) => {
  let valid = false
  let finalResponse
  if (statusCode === 401 || statusCode === 403){
    const refreshToken = localStorage.getItem('refreshToken')
    if (refreshToken){
      localStorage.removeItem('refreshToken')
      const response = await fetchTokenFromRefreshToken(refreshToken)
      if (response){
        finalResponse = await callback()
        if (finalResponse){
          valid = true
        }
      }
    }
  }
  return { tokenRefreshed: valid, newRespBody: finalResponse }
}

const fetchTokenFromRefreshToken = async (refreshToken) => {
  const url = getMappedUrl(FETCH_TOKEN)
  let response
  try {
    response = await api(url, {
      method: 'POST',
      body: {
        grantType: 'Access-Token',
        refreshToken,
      },
    })
  }
  catch (err){
    console.error('Error occurred in refreshing token')
  }
  return response
}

const reFetchResponse = async (uri, params) => {
  const header = prepareHeader()
  set(params, 'headers', new Headers(header))
  const response = await fetchResponse(uri, params)
  console.log('Response from next callback', response)
  return response
}

export async function fetchResponse(uri, params) {
  try {
    const response = await fetch(uri, modifyParams(params))
    const contentType = response.headers.get('content-type')
    if (response.status === 204) {
      return {}
    }
    if (response.ok) {
      storeAuthorization(response.headers)
      removeAuthorization(uri)
      let responseBody
      if (!contentType) responseBody = {}
      else if (contentType.substring(0, 5) === 'text/') {
        responseBody = await response.text()
      }
      else if (contentType.substring(0, 6) === 'image/') {
        responseBody = await response.blob()
      }
      else {
        responseBody = await response.json()
      }
      apiResponse.response = responseBody
      apiResponseCode.code = { code: response.status, uri }
      return responseBody
    }

    const callback = async () => await reFetchResponse(uri, params)
    const { tokenRefreshed, newRespBody } = await refreshToken(response.status, callback)
    if (!tokenRefreshed){
      const error = new Error()
      error.response = response
      error.status = response.status
      apiResponseCode.code = { code: response.status, uri }
      throw error
    }
    else {
      return newRespBody
    }
  }
  catch (err) {
    const error = new Error()
    error.statusCode = err.status
    apiResponseCode.code = { code: err.status, uri }
    error.message = err.message
    if (error.message === 'Failed to fetch') {
      error.message = 'Unable to connect to the server.'
    }
    if (err.response) {
      const errorBody = await err.response.json()
      error.body = errorBody
    }
    throw error
  }
}

const prepareHeader = () => {
  const header = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    pragma: 'no-cache',
    'cache-control': 'no-store',
  }
  authHeader(header)
  return header
}

export async function api(uri, { headers = {}, method = 'GET', body = {} } = {}) {
  const header = prepareHeader()
  const params = modifyParams({
    headers: new Headers(header),
    method,
  })
  if (params.method !== 'GET') {
    params.body = JSON.stringify(body)
  }
  return fetchResponse(uri, params)
}

export async function form(uri, { headers = {}, method = 'GET', body = new FormData() } = {}) {
  const header = {
    Accept: 'application/json',
    pragma: 'no-cache',
    'cache-control': 'no-store',
  }
  authHeader(header)
  const params = modifyParams({
    headers: new Headers(header),
    method,
  })
  return fetchResponse(uri, params)
}

export async function paymentApi(uri, { headers = {}, method = 'GET', body = {} } = {}) {
//   const header = {
//     pragma: 'no-cache',
//     'cache-control': 'no-store',
//   }
  const params = modifyParams({
    // headers: new Headers(header),
    // mode: 'no-cors',
    method,
    body,
  })
  return fetchResponse(uri, params)
}
