import { map } from 'lodash'
import ToastService from './common/ToastService'
import { SEVERITY } from '../constants'

export class ErrorHandlingService {
  static showMessages(messages) {
    const messageService = new ToastService()
    messages.forEach(messageOpts => {
      setTimeout(() => {
        messageService.showMessage(messageOpts)
      }, 0)
    })
  }

  static showMessage({ message, severity = SEVERITY.ERROR }) {
    this.showMessages([{ message, severity }])
  }

  static clearToastMessages() {
    const messageService = new ToastService()
    messageService.clearAllMessages()
  }

  static displayMessageArray({ message, severity = SEVERITY.ERROR }) {
    this.showMessages(map(message, err => ({ message: err, severity })))
  }
}
