/* eslint-disable max-len */
import React, { Component } from 'react'
import cs from 'classnames'
import { Container, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import RightPanel from '../../common/RightPanel'
import st from './style.scss'

export default class SmallMediumBusiness extends Component {
    static propTypes = {
      gotoURL: PropTypes.func.isRequired,
    }
    render() {
      const { gotoURL } = this.props
      return (
        <div>
          <div className={cs(st.create_web_banner)}>
            <img alt="about_banner" src={'/assets/images/about_banner.jpg'} />
          </div>
          <Container>
            <Row className={cs(st.create_web_sec)}>
              <Col lg={8} md={8} xl={8}>
                <div className={cs(st.web_banner)}>
                  <img alt="chess_img" src="/assets/images/sm_business.jpg" />
                </div>
                <h2>Small Medium Business</h2>
                <p>A new multi-country research study focuses on how small and medium-sized businesses (SMBs) have adapted to a changing environment, and how they expect their IT usage and priorities to change in the future
                </p>
                <p> Business development should be accompanied by proper planning (and scaling) of the technologies used. Even the companies who choose technology solutions that meet their current needs &quot;outgrow&quot; them quite quickly and face the need for new development.  Therefore, at the initial stages, we study your business processes and needs in detail, taking into account future growth and scaling, and create a prototype solution and conduct a detailed competitive analysis.</p>
                <div className={cs(st.create_content)}>
                  <h5>Important to Lay the Groundwork for Growth</h5>
                  <p> One of the values that we deliver to our clients is the continuous modernization of the project to contemporary realities and technologies. This means that we constantly monitor the market, technological solutions, and offer our clients technical innovations that can bring profit or that will soon become trends. In this way, our clients receive our strong support with ideas and experience, which allows them to grow faster than their competitors. We also make sure that the technologies and software used for the project are kept up to date and offer timely updates, improvements, infrastructure upgrades, etc. All this allows us to &quot;grow&quot; technical solutions so that the business could be confident in the technical reliability and relevance of the solution used, without investing considerable amounts of money in the development at the initial stage.</p>
                </div>
                <div className={cs(st.create_content)}>
                  <h5>Team, technology and support</h5>
                  <p>The Evergreen team guarantees a business approach to the project: ROI calculation before the start, advice on the best technical solution to meet the customer&lsquo;s requirements, and interests (the view of the technical entrepreneur, not a contractor). We act rationally and are ready to offer an alternative to your solutions: not merely creating a solution at the expense of your idea, but finding a more suitable solution. If a solution ceases to cope with its tasks, we offer to change it to a more effective and cheaper one.</p>
                </div>
              </Col>
              <Col lg={4} md={6} xl={4}>
                <RightPanel active={'services-small-medium-business'} gotoURL={gotoURL} />
              </Col>
            </Row>
          </Container>
        </div>
      )
    }
}
