import React, { Component } from 'react'
import { inject } from 'mobx-react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { replace, get } from 'lodash'
import { HOME_ROUTE } from '../../router'
import Home from './Home'
// import TermsNConditions from './TermsNConditions'
// import PrivacyPolicy from './PrivacyPolicy'
import styles from './style.scss'
import ContactUs from './ContactUs'
import About from './About'
import Products from './Products'
import TechStack from './TechStack'

@inject('mainModel')
export default class MainContent extends Component {
  static propTypes = {
    filter: PropTypes.object.isRequired,
    gotoURL: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    loginWithRedirect: PropTypes.func.isRequired,
    mainModel: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    selectedKey: PropTypes.string,
  }

  static defaultProps = {
    selectedKey: null,
  }

  constructor(props) {
    super(props)
    this.url = window.location.href
  }

  updateSelectedKey = (key) => {
    const { history } = this.props
    const url = `${replace(HOME_ROUTE, ':key', key)}`
    history.push(url)
  }

  render() {
    const { match } = this.props
    let { selectedKey } = this.props
    selectedKey = selectedKey || get(match, 'params.key', '')
    return (
      <div className={classnames(styles.gridRow)}>
        { selectedKey === '' && <Home {...this.props} />}
        { selectedKey === 'products' && <Products {...this.props} />}
        { selectedKey === 'about' && <About {...this.props} />}
        { selectedKey === 'contact-us' && <ContactUs {...this.props} />}
        { selectedKey === 'technology-stack' && <TechStack {...this.props} />}
        {/* { selectedKey === 'terms-conditions' && <TermsNConditions {...this.props} />}
        { selectedKey === 'privacy-policy' && <PrivacyPolicy {...this.props} />} */}

      </div>
    )
  }
}
