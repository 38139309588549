import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cs from 'classnames'
import { Container, InputGroup, FormControl, Col, Row } from 'react-bootstrap'
import { Icon, IconType } from '..'
import st from './style.scss'

export default class MainFooter extends Component {
static propTypes = {
  gotoURL: PropTypes.func.isRequired,
  subscribe: PropTypes.func.isRequired,
}
render() {
  const { Location } = IconType.AARWII
  const { PHONE, MAIL } = IconType.COMMUNICATION
  const { FACEBOOK } = IconType.SOCIAL
  return (
    <div>
      <section className={cs(st.Need_information_panel, st.pp_50)}>
        <Container>
          <div className={cs(st.panel_parent, st.panel_align)}>
            <div className={cs(st.Need_information_panel_content)}>
              <h3>
                <span>Need more information?</span>
              </h3>
              <p>Just WhatsApp your message, we will get back to you shortly
              </p>
            </div>
            <div className={cs(st.panel_group_btn)}>
              <InputGroup className="mb-3">
                <FormControl
                  aria-describedby="basic-addon2"
                  placeholder="Enter your message..."
                     />
                <InputGroup.Text className={cs(st.whatspp_btn)} id="basic-addon2">WHATSAPP</InputGroup.Text>
              </InputGroup>
            </div>
          </div>
        </Container>
      </section>
      <section className={cs(st.footer_section)}>
        <Container className={cs(st.footer_cc)}>
          <Row>
            <Col lg={4} md={4} sm={12} xl={4} xs={12}>
              <div className={cs(st.footer_col_1)}>
                <div className={cs(st.footer_title)}>
                  <h5>ABOUT US</h5>
                </div>
                <div className={cs(st.logo)}>
                  <img alt="logo" src="/assets/images/aarwii-logo.png" />
                </div>
                <div className={cs(st.footer_content)}>
                  <p>We, the Architects who designed stunning<br /> solutions for Fortune 500 companies. <br />
                    Our journey started in 2004..
                  </p>
                </div>
                <ul>
                  <li>
                    <div>
                      <Icon
                        color={'#f08200'} height={'15'} names={[Location]} viewHeight={'20'} viewWidth={'20'}
                        width={'15'} />
                    </div>
                    <div>
                      Amaravati, AP <br />Hyderabad, TS
                    </div>
                  </li>
                  <li>
                    <div>
                      <Icon color={'#f08200'} height={'15'} names={[PHONE]} width={'15'} />
                    </div>
                    <div>
                      +91 9121 900 900
                    </div>
                  </li>
                  <li>
                    <div>
                      <Icon color={'#f08200'} height={'15'} names={[MAIL]} width={'15'} />
                    </div>
                    <div>
                      contact@aarwii.com
                    </div>
                  </li>
                </ul>
                <div className={cs(st.policies_terms)}>
                  <div onClick={() => this.props.gotoURL('/technology-stack')}>Technology Stack</div>
                </div>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12} xl={4} xs={12}>
              <div className={cs(st.footer_col_1)}>
                <div className={cs(st.footer_title)}>
                  <h5>RECENT POSTS</h5>
                </div>
                <div className={cs(st.create_ident_title)}>
                  <h6><div onClick={() => this.props.gotoURL('/blogs/create-web-identity')}>
                    Create Your Identity</div></h6>
                </div>
                <div className={cs(st.meta)}>
                  By Admin / 17 October 2019
                </div>
                <div className={cs(st.create_ident_content)}>
                  <p>You need, your own space to <br />create identity, a mass solution..</p>
                </div>
                <div className={cs(st.create_ident_title, st.mt_20)}>
                  <h6>Why a custom solution?</h6>
                </div>
                <div className={cs(st.meta)}>
                  By Admin / 16 Aug 2019
                </div>
                <div className={cs(st.create_ident_content)}>
                  <p>A unique shape, design brings <br />identity to your business.</p>
                </div>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12} xl={4} xs={12}>
              <div className={cs(st.footer_col_1)}>
                <div className={cs(st.footer_title)}>
                  <h5>SOCIAL</h5>
                </div>
                <div className={cs(st.footer_col_1)}>
                  <ul>
                    <li>
                      <div>
                        <Icon
                          color={'#f08200'}
                          height={'20'} names={[FACEBOOK]}
                          width={'20'}
                          />
                      </div>
                      <div>
                        <strong className={cs(st.strong_text)}>@AARWII</strong>
                        Web presence, quality of services create leads to sales.
                      </div>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <div>
                        <Icon
                          color={'#f08200'}
                          height={'20'} names={[FACEBOOK]}
                          width={'20'}
                          />
                      </div>
                      <div>
                        <strong className={cs(st.strong_text)}>@AARWII</strong>
                        Customer don&lsquo;t tuning web services is a constant activity like watering a plant
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>

          </Row>
        </Container>
      </section>
    </div>
  )
}
}
