import { get } from 'lodash'

import { api } from './api-helper'
// import {  form } from './api-helper'
import { SEVERITY, getMappedUrl, REGISTER_USER, VALIDATE_OTP, LOGIN_USER } from '../constants'
import { ErrorHandlingService } from '.'

export async function registerUser(payload = {}) {
  let response
  try {
    const url = getMappedUrl(REGISTER_USER)
    response = await api(url, {
      method: 'POST',
      body: payload,
    })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.details'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function loginUser(payload = {}) {
  let response
  try {
    const url = getMappedUrl(LOGIN_USER)
    response = await api(url, {
      method: 'POST',
      body: payload,
    })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.error'), severity: SEVERITY.ERROR })
  }
  return response
}

export async function validateOTP(payload = {}) {
  let response
  try {
    const url = getMappedUrl(VALIDATE_OTP)
    response = await api(url, {
      method: 'POST',
      body: payload,
    })
  }
  catch (err) {
    ErrorHandlingService.showMessage({ message: get(err, 'body.details'), severity: SEVERITY.ERROR })
  }
  return response
}
