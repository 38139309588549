/* eslint-disable max-len */
import React, { Component } from 'react'
import cs from 'classnames'
import { Container, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import RightPanel from '../../common/RightPanel'
import st from './style.scss'

export default class WebDesign extends Component {
    static propTypes = {
      gotoURL: PropTypes.func.isRequired,
    }
    render() {
      const { gotoURL } = this.props
      return (
        <div>
          <div className={cs(st.create_web_banner)}>
            <img alt="about_banner" src={'/assets/images/about_banner.jpg'} />
          </div>
          <Container>
            <Row className={cs(st.create_web_sec)}>
              <Col lg={8} md={8} xl={8}>
                <div className={cs(st.create_web_banner)}>
                  <img alt="chess_img" src="/assets/images/web_design.jpg" />
                </div>
                <h2>Web Design</h2>
                <p>A decado ago technology was only limited to large businesses or enterprises,
                  but the evolution created hundreds new era technologies and solutions.
                </p>
                <p> The big monitor became small which can fit in palm to take
                  your instruction with little gentle touch. Yes, we are talking about the smart phone.
                  Today almost everyone uses smart phone to quickly browse internet than going to laptop
                  or desktop. However, desktop users are always there and of course it will continue to be there,
                  however the primary design specifications comes from the laptop/desktop screen size.
                  The most browser support resize of the screen efficiently than earlier so giving user
                  the same experience in variety of the screens sizes. When a website is designed, the foremost
                  important element is compatibility with various screen sizes with
                  Mobile First approach which means giving the best look and feel for mobile screen sizes.</p>
                <p> A webpage should create impressive look and presentation with rich colors scheme,
                  image and textual representation by leaving the user exceptional experience which can be remembered for a long time.
                  It should leave the band symbolism and significance so that users can easily recollect and
                  be familiar with the site and the brand.</p>
                <div className={cs(st.create_content)}>
                  <h5>Navigation and Arrangement of screen.</h5>
                  <p>One’s aspect is very different in desktop verses mobile views because of it’s screen navigation pattern.
                    The desktop approach follows the vertical scrolling whereas the mobile can leverage the horizontal
                    navigation along with the vertical. By the dimensional representation, the mobile is in portrait style
                    whereas the desktop or laptop are presented in landscape. It makes the big difference in designing the
                    arrangement of elements and sections. In order to give user a good feel any element must meet a basic
                    screen resolution requirement with perfectly chopped height and width, so that the presentation module can be visible at its best
                    scale by getting the rearrangement plotted in a transposition way or the horizontal scroll approach.</p>
                </div>
                <div className={cs(st.create_content)}>
                  <h5>Presentation</h5>
                  <p>Most of professional service users like simple patterns than zigzag or
                    radium colors so choose the appealing font and limit the text that visible
                    on landing page. Don’t try to explain everything on just first page, when you
                    create impression they would eventually like to browse further. So you can
                    create blogs and image gallery where users like to read more or explore beautiful
                    images about your services and customer experience. One of the most important section
                    is the testimonials where you share the experience of your customers with the new users
                    who like to explore your services. Organize the design patterns in a way either you go for very
                    right presentation or a perfect professional look but never mix both. Asking expert advice is preferable.</p>
                </div>
                <div className={cs(st.create_content)}>
                  <h5>The cards presentation</h5>
                  <p>  One of the best presentation styles followed in todayâ€™s web design team
                    is the presenting viewable elements into cards. Cards presentation carries a perfect
                    shaped image with rich presentation styles and textual content
                    by describing image and it contains a link for viewer to access full content.</p>
                  <p> The card presentation is very much used in cases like showing profile, about the team or any individual
                    whose information can be presenting with image and textual content.</p>

                </div>
                <div className={cs(st.create_content)}>
                  <h5>The Carousel Effect</h5>
                  <p> Most of the web users of Microsoft Office users should have experience the slide show, right ?
                    exactly the web even in a static page can bring such great feel with the Carousel effect.
                    Almost in every website starting from the single page site to a large corporate or product
                    landing page just appears with rich presentation of beautiful images in vibrant colours.
                    Generally the effect contains 3-4 pages but not more, so that user will have change to see
                    what website landing page talks instead of skipping.
                    Numerous website use opening or the slide show effect along with the Carousel effect.</p>

                </div>
              </Col>
              <Col lg={4} md={6} xl={4}>
                <RightPanel active={'services-web-design'} gotoURL={gotoURL} />
              </Col>
            </Row>
          </Container>
        </div>
      )
    }
}
