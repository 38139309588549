import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Services from './Services'
import WebDesign from './Services/WebDesign'
import CloudHosting from './Services/CloudHosting'
import Consulting from './Services/Consulting'
import SmallMediumBusiness from './Services/SmallMediumBusiness'
import SecureSolutions from './Services/SecureSolutions'
import CreateIdentity from './Blogs/CreateIdentity'
import MobileFriendly from './Blogs/MobileFriendly'
import SelectWebDesign from './Blogs/SelectWebDesign'

export default class InformativeContent extends Component {
  static propTypes = {
    gotoURL: PropTypes.func.isRequired,
    selectedSubKey: PropTypes.string.isRequired,
  }

  getRenderComponent() {
    const { selectedSubKey, gotoURL } = this.props
    switch (selectedSubKey) {
      case 'web-design':
        return <WebDesign gotoURL={gotoURL} />
      case 'small-medium-business':
        return <SmallMediumBusiness gotoURL={gotoURL} />
      case 'it-consulting':
        return <Consulting gotoURL={gotoURL} />
      case 'cloud-hosting':
        return <CloudHosting gotoURL={gotoURL} />
      case 'secure-solutions':
        return <SecureSolutions gotoURL={gotoURL} />
      case 'create-web-identity':
        return <CreateIdentity gotoURL={gotoURL} />
      case 'mobile-friendly-website':
        return <MobileFriendly gotoURL={gotoURL} />
      case 'how-to-select-web-design':
        return <SelectWebDesign gotoURL={gotoURL} />
      default:
        return <Services gotoURL={gotoURL} />
    }
  }

  render() {
    return (
      <div>
        { this.getRenderComponent() }
      </div>
    )
  }
}
