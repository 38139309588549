/* eslint-disable max-len */
import React, { Component } from 'react'
import cs from 'classnames'
import { Container, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import RightPanel from '../../common/RightPanel'
import st from './style.scss'

export default class Consulting extends Component {
    static propTypes = {
      gotoURL: PropTypes.func.isRequired,
    }
    render() {
      const { gotoURL } = this.props
      return (
        <div>
          <div className={cs(st.create_web_banner)}>
            <img alt="about_banner" src={'/assets/images/about_banner.jpg'} />
          </div>
          <Container>
            <Row className={cs(st.create_web_sec)}>
              <Col lg={8} md={8} xl={8}>
                <div className={cs(st.create_web_banner)}>
                  <img alt="chess_img" src="/assets/images/consultation.jpg" />
                </div>
                <h2>IT Consulting</h2>
                <p>A company excel it&lsquo;s productive and the marketing reach by only enablement of technology. Industries delay on the IT consulting partners deliver business solutions.
                </p>
                <div className={cs(st.create_content)}>
                  <h5>Navigation and Arrangement of screen.</h5>
                  <ul><li>A full-spectrum of development and support of solutions for small and medium business: software, front-office systems, chatbots, websites, shops, CRM, AI-solutions, digital marketing materials. All processes are under the supervision of one development company (Evergreen), all competencies are covered by full-time employees with extensive experience. No re-outsourcing or &quot;cocktails&quot; of performers on your project. Proper integration of our solution with the company&lsquo;s existing IT infrastructure.</li>
                    <li> Flexibility, high speed of new functions introduction, and implementation opportunities after project launch. A documented solution that can be developed and maintained without having to be restarted from scratch after one year.</li>
                    <li>A warranty period after launch, and a lifetime warranty that is subject to the signing of a support agreement.</li>
                    <li> Product marketing assistance (content marketing, SEO-strategy), recommendations on promotion, and customization of analytics (funnels, panels).</li>
                    <li> Ease of communication: we are open to communication and speak the language of the project.</li>
                    <li> A business approach to the project, ROI calculation before the start, advice on the best technical solution (the view of the technical entrepreneur, not a mere contractor).</li></ul>
                </div>
              </Col>
              <Col lg={4} md={6} xl={4}>
                <RightPanel active={'services-it-consulting'} gotoURL={gotoURL} />
              </Col>
            </Row>
          </Container>
        </div>
      )
    }
}
