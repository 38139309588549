/* eslint-disable max-len */
import React, { Component } from 'react'
import cs from 'classnames'
import { Container, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import RightPanel from '../../common/RightPanel'
import st from './style.scss'

export default class TechStack extends Component {
    static propTypes = {
      gotoURL: PropTypes.func.isRequired,
    }
    render() {
      const { gotoURL } = this.props
      return (
        <div>
          <div className={cs(st.create_web_banner)}>
            <img alt="about_banner" src={'/assets/images/about_banner.jpg'} />
          </div>
          <Container>
            <Row className={cs(st.create_web_sec)}>
              <Col lg={8} md={8} xl={8}>
                <div className={cs(st.tech_stack_header)}>
                  <img alt="tech_stack" src="/assets/images/technology_stack.jpg" />
                  <h4>Technology Stack</h4>

                  <div className={cs(st.tech_stack_dtl)}>
                    <p>A full stack coverage of technology stream from User Interface design to backend data processing. In addition to that we have expertise in integration technology to enable b2b communication for the enterprise.</p>
                    <div className={cs(st.tech_header)}>
                      <h2> UX/UI Design</h2>
                      <p>Responsive design for the websites providing user seemless experience on all variety of devices including laptop, mobile, tablet etc. Our UX/UI expertise covers compitative edge designs for impactful mobile applications.</p>
                      <ul>
                        <li>Bootstrap</li>
                        <li>Adobe XD</li>
                        <li>Photoshop</li>
                        <li>HTML and CSS</li>
                      </ul>
                    </div>
                  </div>
                  <div className={cs(st.tech_header)}>
                    <h2> Frontend Technology</h2>
                    <p>User experience matters which not only limited to graphical presentation but user interactions and page loading time. A seamless flow of data for user select product until completion of payment make user revisit or carry an experience than just using service makes difference</p>
                    <ul>
                      <li>React JS</li>
                      <li>Angular</li>
                      <li>Javascript</li>
                      <li>JQuery</li>
                      <li>numerous other plug-ins</li>
                    </ul>
                  </div>
                  <div className={cs(st.tech_header)}>
                    <h2> Backend Technology</h2>
                    <p>High performance and secure solution is what drives today‘s competitive market. We have explored and implemented multi-layered architecture that delivers performance and security.</p>
                    <ul>
                      <li>Java</li>
                      <li>Spring (REST, SECURITY, MVC, JPA..)</li>
                      <li>MySQL</li>
                    </ul>
                  </div>
                  <div className={cs(st.tech_header)}>
                    <h2> Integration Technology</h2>
                    <p>Our team expertise covers enterprise grade products like webMethods which is backbone of thousands of poineered customers across the world.</p>
                    <ul>
                      <li>webMethods Integration Server</li>
                      <li>Universal Messaging</li>
                      <li>Command Central</li>
                      <li>BPM etc..</li>
                    </ul>
                  </div>
                  <div className={cs(st.tech_header)}>
                    <h2> Cloud Technology</h2>
                    <p>Our core product <a href="https://www.resumaker.co.in">Resumaker</a> is built and runs 100% on cloud. Our team has strong implementation knowledge at least 20+ AWS services and similar scale on Microsoft Azure.</p>
                    <ul>
                      <li>Amazon Web Service (AWS)</li>
                      <li>Microsoft Azure</li>
                      <li>Linux, VM, Storage</li>
                    </ul>
                  </div>
                  <div className={cs(st.tech_header)}>
                    <h2> Mapping Technology</h2>
                    <p>Working with enterprises doesn‘t limit to a specific use case but one should have variety of business demand. A mapping plays key role in enabling the organization connect with the partners.</p>
                    <ul>
                      <li>Altova MapForce</li>
                      <li>Java</li>
                      <li>webMethods Flow</li>
                    </ul>
                  </div>
                  <div className={cs(st.tech_header)}>
                    <h2> Deployment Technology</h2>
                    <p>One must master in how the data sets are stored and released to production.</p>
                    <ul>
                      <li>Git</li>
                      <li>Maven</li>
                      <li>Jenkins</li>
                    </ul>
                  </div>
                  <div className={cs(st.tech_header)}>
                    <p>A solution demands not limited to above but other technologies by the use case. We can‘t stop here, it‘s a continuous learning journey. Upskill is in DNA, the timing is different on what comes first and which is later.</p>
                  </div>
                </div>
              </Col>
              <Col lg={4} md={6} xl={4}>
                <RightPanel active={'technology-stack'} gotoURL={gotoURL} />
              </Col>
            </Row>
          </Container>
        </div>
      )
    }
}
