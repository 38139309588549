import { isFunction, get } from 'lodash'
import { getBlogList, getBlogData, getInterviewQuestionsCategories, getInterviewQuestionsList, getCareerGuideList,
  getCareerGuideSectionData, getTemplates, subscribeNewSLetter, generateCaptcha, contactus, getRecentBlogs,
  getRelatedBlogs, getInterviewQuestionsDetails, getRelatedInterviewQuestions } from '../services'

export default class MainModel {
  async getBlogList(filter, cb = () => { }) {
    const response = await getBlogList(filter)
    if (response && isFunction(cb)){
      cb(get(response, 'blogViewLst'), get(response, 'totBlogPagesCount'))
    }
    return response
  }

  async getRecentBlogs(cb = () => { }) {
    const response = await getRecentBlogs()
    if (response && isFunction(cb)){
      cb(get(response, 'blogViewLst'))
    }
    return response
  }

  async getRelatedBlogs(cb = () => { }) {
    const response = await getRelatedBlogs()
    if (response && isFunction(cb)){
      cb(get(response, 'blogViewLst'))
    }
    return response
  }

  async subscribeNewSLetter(email, cb = () => { }){
    const response = await subscribeNewSLetter(email)
    if (response && isFunction(cb)){
      cb(get(response, 'details'))
    }
    return response
  }

  async getBlogData(blogId, cb = () => { }) {
    const response = await getBlogData(blogId)
    if (response && isFunction(cb)){
      cb(get(response, 'blogView'))
    }
    return response
  }

  async getInterviewQuestionsCategories(pageNo, cb = () => { }) {
    const response = await getInterviewQuestionsCategories(pageNo)
    if (response && isFunction(cb)){
      cb(get(response, 'catList'))
    }
    return response
  }

  async getInterviewQuestionsList(payload = {}, cb = () => { }) {
    const response = await getInterviewQuestionsList(payload)
    if (response && isFunction(cb)){
      cb(get(response, 'iqList'))
    }
    return response
  }

  async getInterviewQuestionsDetails(id, cb = () => { }) {
    const response = await getInterviewQuestionsDetails(id)
    if (response && isFunction(cb)){
      cb(get(response, 'iq'))
    }
    return response
  }

  async getRelatedInterviewQuestions(category, count, cb = () => { }) {
    const response = await getRelatedInterviewQuestions(category, count)
    if (response && isFunction(cb)){
      cb(get(response, 'iqList'))
    }
    return response
  }

  async getCareerGuideList(pageNo, cb = () => { }) {
    const response = await getCareerGuideList(pageNo)
    if (response && isFunction(cb)){
      cb(get(response, 'viewList'))
    }
    return response
  }

  async getCareerGuideSectionData(careerId, cb = () => { }) {
    const response = await getCareerGuideSectionData(careerId)
    if (response && isFunction(cb)){
      cb(get(response, 'careerGuide'))
    }
    return response
  }

  async fetchTemplates(cb = () => { }) {
    const response = await getTemplates()
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }

  async generateCaptcha(cb = () => { }) {
    const response = await generateCaptcha('1')
    if (response && isFunction(cb)){
      cb(get(response, 'capcha'))
    }
    return response
  }

  async postContactDetails(payload, cb = () => { }) {
    const response = await contactus(payload)
    if (response && isFunction(cb)){
      cb(response)
    }
    return response
  }
}
