import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import cs from 'classnames'
import { withRouter } from 'react-router-dom'
import { Nav, Navbar, NavDropdown, Container } from 'react-bootstrap'
import st from './style.scss'
import { Icon, IconType } from '..'

class MainHeader extends Component {
  static propTypes = {
    gotoURL: PropTypes.func.isRequired,
    sideBarMini: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    loginWithRedirect: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    toggleSideBar: PropTypes.func,
  }

  static defaultProps = {
    sideBarMini: false,
    toggleSideBar: _ => _,
  }

  constructor(props){
    super(props)
    this.state = {
      top: window.pageYOffset === 0,
    }
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    this.setState({
      top: window.pageYOffset === 0,
    })
  }

  renderLogo = () => {
    const { FACEBOOK } = IconType.SOCIAL
    const { MAIL, PHONE } = IconType.COMMUNICATION
    const { top } = this.state

    return (
      <Fragment>
        <div className={cs(st.fragment_header)}>
          <Container className={cs(st.top_header)}>
            <div className={cs(st.top_header_items)}>
              <div className={cs(st.header_left_items)}>
                <div className={cs(st.left_items)}>
                  <p> <span className={cs(st.contact_details_icon)}>
                    <Icon color={'#f08200'} height={'15'} names={[PHONE]} styles={'icon25 darkGrayAlt'} width={'15'} />
                  </span> +91 9121 900 900 </p>
                </div>
                <div className={cs(st.left_items)}>
                  <p> <span className={cs(st.contact_details_icon)}>
                    <Icon color={'#f08200'} height={'15'} names={[MAIL]} styles={'icon25 darkGrayAlt'} width={'15'} />
                  </span> contact@aarwii.com </p>
                </div>
              </div>
              <div className={cs(st.header_right_items)}>
                {/* <div className={cs(st.right_items)}>
                  <p> <span className={cs(st.contact_details_icon)} /> Pricing </p>
                </div>
                <div className={cs(st.right_items)}>
                  <p> <span className={cs(st.contact_details_icon)} /> How it works ? </p>
                </div> */}
                <div className={cs(st.right_items)}>
                  <p> <span className={cs(st.contact_details_icon)}>
                    <Icon
                      color={'#f5f5f5'}
                      height={'20'} names={[FACEBOOK]}
                      styles={'icon10 darkGrayAlt'}
                      width={'20'}
                          /></span>
                  </p>
                </div>

              </div>
            </div>
          </Container>
        </div>
        <div className={cs(st.nav_header)}>
          <Container className={cs(st.cc_fluid_pp, { [st.nav_noshadow]: top }, { [st.nav_shadow]: !top })}>
            {/* <div className={cs({ [st.nav_noshadow]: top }, { [st.nav_shadow]: !top })}> */}
            <Navbar className={cs(st.nav_color, st.nav_basic, 'container')} collapseOnSelect expand="lg">
              <Navbar.Brand className={cs(st.nav_brand_top_logo)}>
                <div onClick={() => this.props.gotoURL('/')}>
                  <img
                    alt="Resumaker Logo"
                    className="d-inline-block align-top navbar-nav-svg"
                    height="auto"
                    src="../assets/images/logo.png"
              /></div>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" className={cs(st.nav_responsive_mr)} />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto" />
                <Nav>
                  <NavDropdown
                    className={cs(st.nav_list_padding, st.nav_title)}
                    id="collasible-nav-dropdown" onClick={() => this.props.gotoURL('/')} title="Home" />
                </Nav>
                <Nav>
                  <NavDropdown
                    className={cs(st.nav_title)} id="collasible-nav-dropdown"
                    onClick={() => this.props.gotoURL('/about')} title="About" />
                </Nav>
                <Nav>
                  <NavDropdown
                    className={cs(st.nav_title, st.blogs_level_nav)}
                    id="collasible-nav-dropdown" onClick={() => this.props.gotoURL('/products')} title="Products" />
                </Nav>
                <Nav>
                  <NavDropdown
                    className={cs(st.nav_title, st.hire_expert_nav)}
                    id="collasible-nav-dropdown" onClick={() => this.props.gotoURL('/services')} title="Services" />
                </Nav>
                <Nav>
                  <NavDropdown
                    className={cs(st.nav_title, st.hire_expert_nav)}
                    id="collasible-nav-dropdown" onClick={() => this.props.gotoURL('/technology-stack')}
                    title="Technology Stack" />
                </Nav>
                <Nav>
                  <NavDropdown
                    className={cs(st.nav_title, st.hire_expert_nav)}
                    id="collasible-nav-dropdown" onClick={() => this.props.gotoURL('/contact')}
                    title="Contact" />
                </Nav>
              </Navbar.Collapse>
            </Navbar>
            {/* </div> */}
          </Container>
        </div>
      </Fragment>
    )
  }
  render = () => (
    <Fragment>
      {this.renderLogo()}
    </Fragment>
  )
}

MainHeader.propTypes = {
  alignLeft: PropTypes.bool.isRequired,
  applicationModel: PropTypes.object.isRequired,
  history: PropTypes.object,
  node: PropTypes.node,
  onSelect: PropTypes.func,
  selectedTab: PropTypes.number,
  showLogout: PropTypes.bool,
  tabBody: PropTypes.array.isRequired,
}

MainHeader.defaultProps = {
  node: null,
  history: {},
  selectedTab: 0,
  onSelect: () => {},
  showLogout: true,
}
export default withRouter(MainHeader)
