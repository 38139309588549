export const GET_BLOG_LIST = 'api/blog/getBlogData'
export const GET_RECENT_BLOGS = 'api/blog/getRecentBlogs'
export const GET_BLOG_DATA = 'api/blog/getBlogDetailData'
export const GET_RELATED_BLOGS = 'api/blog/getRelatedBlogs'
export const GET_INTERVIEW_QS_CATEGORIES = 'api/iq/getCategoryList'
export const GET_INTERVIEW_QS_SECTION = 'api/iq/getSectionInfo'
export const GET_INTERVIEW_QS_LIST = 'api/iq/getInterviewQuestions'
export const GET_INTERVIEW_QS_DATA = 'api/iq/getIQData'
export const GET_RELATED_INTERVIEW_QS = 'api/iq/getRealtedInterviewQuestions'
export const GET_CAREER_GUIDE_LIST = 'api/career/guide/list'
export const GET_CAREER_GUIDE_SECTION = 'api/career/getCareerGuideDetailData'
export const SUBSCRIBE_NEWSLETTER = 'api/guest/email/subscribe'
export const GENERATE_CAPTCHA = 'api/guest/generateCapcha'
export const CONTACT_US = 'api/guest/contact'
