import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cs from 'classnames'
import st from './style.scss'

export default class RightPanel extends Component {
static propTypes = {
  active: PropTypes.string,
  gotoURL: PropTypes.func.isRequired,
}
static defaultProps = {
  active: 'about',
}

render() {
  const { active } = this.props
  return (
    <div className={cs(st.page_sidebar)}>
      <div className={cs(st.sidebar)}>
        <div className={cs(st.sidebar_nav)}>
          <ul>
            <li className={cs({ [st.active]: active === 'about' })}>
              <div onClick={() => this.props.gotoURL('/about')}>Company Overview</div></li>
            <li className={cs({ [st.active]: active === 'products' })}>
              <div onClick={() => this.props.gotoURL('/products')}>Products</div></li>
            <li className={cs({ [st.active]: active === 'services-web-design' })}>
              <div onClick={() => this.props.gotoURL('/services/web-design')}>Web Design</div></li>
            <li className={cs({ [st.active]: active === 'services-small-medium-business' })}>
              <div onClick={() => this.props.gotoURL('/services/small-medium-business')}>
                Small Medium Business</div></li>
            <li className={cs({ [st.active]: active === 'services-it-consulting' })}>
              <div onClick={() => this.props.gotoURL('/services/it-consulting')}>Consulting</div></li>
            <li className={cs({ [st.active]: active === 'services-cloud-hosting' })}>
              <div onClick={() => this.props.gotoURL('/services/cloud-hosting')}>Cloud Hosting</div></li>
            <li className={cs({ [st.active]: active === 'services-secure-solutions' })}>
              <div onClick={() => this.props.gotoURL('/services/secure-solutions')}>Secure  Solutions</div></li>
            <li className={cs({ [st.active]: active === 'technology-stack' })}>
              <div onClick={() => this.props.gotoURL('/technology-stack')}>Technology Stack</div></li>
          </ul>
        </div>
        <div className={cs(st.brochure)}>
          <p className={cs(st.ask_expert, st.button_border)}>
            <a href="https://wa.me/919121900900" target="_blank">
              Ask Our Experts</a>
          </p>
        </div>
      </div>
    </div>
  )
}
}
